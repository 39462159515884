import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
	static targets = ["input", "progress"];

	connect() {
		this.progressTarget.querySelector("#cancel-upload").onclick = (e) => {
			// reset all attributes
			this.inputTarget.value = null;
			this.inputTarget.disabled = false;
			this.progressTarget.classList.add("hidden");
			this.progressTarget.querySelector("#progress-percentage").innerText =
				"0%";
			this.progressTarget.querySelector("#progress-bar").style.width = "0";
			this.progressTarget
				.querySelector("#upload-preview")
				.classList.add("hidden");
			this.inputTarget.form
				.querySelector("input[data-upload-blob='true']")
				.remove();
			e.stopPropagation(); // prevent modal from closing
		};
	}

	uploadFile(event) {
		const url = this.inputTarget.dataset.directUploadUrl;

		Array.from(this.inputTarget.files).forEach((file) => {
			const upload = new DirectUpload(
				file,
				url,
				this, // callback directUploadWillStoreFileWithXHR(request)
			);
			upload.create((error, blob) => {
				if (error) {
					console.log(error);
				} else {
					this.createHiddenBlobInput(blob);
					// if you're not submitting a form after upload. you need to attach
					// uploaded blob to some model here and skip hidden input.
					const urlCreator = window.URL || window.webkitURL;
					const previewUrl = urlCreator.createObjectURL(file);
					if (file.type.includes("image")) {
						this.createPreview(previewUrl, "img");
					} else if (file.type.includes("video")) {
						this.createPreview(previewUrl, "video");
					}
					const filename = this.progressTarget.querySelector("#filename");
					if (filename !== null) {
						filename.innerText = file.name;
					}
				}
			});
		});
		this.inputTarget.disabled = true;
	}

	// add blob id to be submitted with the form
	createHiddenBlobInput(blob) {
		let hiddenField = this.inputTarget.form.querySelector(
			"input[data-upload-blob='true']",
		);
		if (hiddenField == null) {
			// create new element if it doesn't exist
			hiddenField = document.createElement("input");
			hiddenField.name = this.inputTarget.name;
			hiddenField.setAttribute("type", "hidden");
			hiddenField.setAttribute("data-upload-blob", "true");
			this.inputTarget.form.appendChild(hiddenField);
		}
		hiddenField.setAttribute("value", blob.signed_id);
	}

	createPreview(previewUrl, kind) {
		let preview = this.progressTarget.querySelector("#upload-preview");
		let classes = [];
		if (preview !== null) {
			classes = preview.classList;
			preview.remove();
		}
		preview = document.createElement(kind);
		this.progressTarget.appendChild(preview);
		preview.id = "upload-preview";
		preview.classList = classes;
		preview.setAttribute("src", previewUrl);
		preview.classList.remove("hidden");
	}

	directUploadWillStoreFileWithXHR(request) {
		request.upload.addEventListener("progress", (event) => {
			this.progressUpdate(event);
		});
	}

	progressUpdate(event) {
		const progress = (event.loaded / event.total) * 100;
		const formattedProgress = `${parseFloat(progress).toFixed(2)}%`;

		this.progressTarget.classList.remove("hidden");
		this.progressTarget.querySelector("#progress-percentage").innerText =
			formattedProgress;
		this.progressTarget.querySelector("#progress-bar").style.width =
			formattedProgress;
		this.inputTarget.form.querySelector("input[type='submit']").disabled =
			progress !== 100;

		// if you navigate away from the form, progress can still be displayed
		// with something like this:
		// document.querySelector("#global-progress").innerHTML = progress;
	}
}
