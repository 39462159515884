import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="reveal"
export default class extends Controller {
  static targets = ["revealable", "openButton", "closeButton"];
  static values = {
    revealed: {
      type: Boolean,
      default: false,
    },
  };
  connect() {
    if (this.revealedValue) this.toggle();
  }

  toggle(event) {
    this.openButtonTarget.classList.toggle("hidden");
    this.closeButtonTarget.classList.toggle("hidden");
    this.revealableTarget.classList.toggle("hidden");
  }
}
