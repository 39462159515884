import "@hotwired/turbo-rails";
import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";

const application = Application.start();

application.debug = process.env.NODE_ENV === "development";
application.warnings = true;
window.Stimulus = application;

const controllers = import.meta.glob("../**/*_controller.js", { eager: true });
registerControllers(application, controllers);

// Import all channels.
const channels = import.meta.glob("../**/*_channel.js");

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

// Example: Import a stylesheet in app/frontend/index.css
// import "./application.css";
