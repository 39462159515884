import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="video-reveal"
export default class extends Controller {
  static targets = ["video", "placeholder"];
  connect() {}

  toggle(event) {
    this.videoTarget.classList.toggle("hidden");
    this.placeholderTarget.classList.toggle("hidden");
  }
}
