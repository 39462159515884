import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="file-input"
export default class extends Controller {
	static values = { submitButtonId: String, imagePreviewId: String };

	connect() {
		this.submitButton = document.getElementById(this.submitButtonIdValue);
		this.imagePreview = document.getElementById(this.imagePreviewIdValue);

		// 10MB in bytes -> 10 * 1024 * 1024
		this.maxFileSize = 10485760;
	}

	toggle(event) {
		this.checkFileSize(event);
		this.submitButton.classList.remove("hidden");
		this.imagePreview.classList.remove("hidden");
		this.imagePreview.querySelector("img").src = URL.createObjectURL(
			event.currentTarget.files[0],
		);
	}

	checkFileSize(event) {
		let exceedsLimit = event.currentTarget.files[0].size > this.maxFileSize;

		// this.errorMessageTarget.classList.toggle("hidden", !exceedsLimit);
		if (exceedsLimit) event.currentTarget.form.reset();
	}
}
